export const generateAppConfig = () =>{
    let exportResult = {
        appRepoLocation:"http://localhost:3000",
        backendUrl: "http://localhost:3100",
        jsReportUrl: "http://localhost:5488",
        travelAgentBackendUrl: "http://localhost:3000",
        adminRepoLocation: "http://localhost:3000",
        bookingAppRepoLocation: "http://localhost:3001",
    };
    if(process.env.REACT_APP_PRODUCTION_MODE ==='TEST'){
        exportResult.appRepoLocation = "https://sg-app.tunabay.com.my";
        exportResult.backendUrl = "https://sg-api.tunabay.com.my/admin";
        exportResult.jsReportUrl= "https://sg-report.tunabay.com.my";
        exportResult.travelAgentBackendUrl =  "https://sg-api.tunabay.com.my/business";
        exportResult.adminRepoLocation = "https://sg-admin.tunabay.com.my";
        exportResult.bookingAppRepoLocation = "https://sg-booking.tunabay.com.my";
    }
    if(process.env.REACT_APP_PRODUCTION_MODE ==='PROD'){
        exportResult.appRepoLocation = "https://app.tunabay.com.my";
        exportResult.backendUrl = "https://api.tunabay.com.my/admin";
        exportResult.jsReportUrl= "https://report.tunabay.com.my";
        exportResult.travelAgentBackendUrl =  "https://api.tunabay.com.my/business";
        exportResult.adminRepoLocation = "https://admin.tunabay.com.my";
        exportResult.bookingAppRepoLocation = "https://booking.tunabay.com.my";
    }
 
    return exportResult;
}