import React from "react";
import { generateAppConfig } from "../../config";

const createNewBookingEngine = ({ add, edit, reservationId, bulkBooking = false, bulkBookingId = "", isHref = false }) => {
    const userInfo = JSON.parse(localStorage.getItem("accessToken"));
    let url = `${generateAppConfig().bookingAppRepoLocation}/?userId=${userInfo?.userId}&token=${userInfo?.accessToken}&firstName=${userInfo?.firstName}&lastName=${userInfo?.lastName}`
    if(isHref){
      return url;
     }
    if(edit){
      url += `&reservationId=${reservationId}`
    }
    if(bulkBooking){
      url += `&bulkBookingId=${bulkBookingId}`
    }
    window.open(url, "_blank", "noreferrer");
};

export default createNewBookingEngine;
